import { database } from '../services/firebase';
import { speakMsg } from '../utils/speaking';
import {
  GOT_LIST,
  SHOULD_SHOW_CHECKLIST,
  SHOULD_SHOW_CHECKLIST_FORM,
  SET_ACTIVE_LIST_KEY,
  ITEM_ADDED,
  ITEM_REMOVED,
  CLEAR_ITEMS,
  TOGGLE_COMPLETED,
  ITEM_CHANGED,
  ITEMS_ORDER_CHANGED,
  CHECKLIST_DELETED
} from '../constants/action-types';
import {
  saveChecklist,
  addItemToList,
  removeItemFromList,
  updateChecklistItemOrder,
  updateItemTextOnFirebase,
  deleteChecklistFromDb
} from '../api/checklist-api';

export function createChecklist(name) {
  return async dispatch => {
    await saveChecklist(name);
    dispatch(hideChecklistForm());
  };
}

export function deleteChecklist(listKey) {
  return async dispatch => {
    const result = await deleteChecklistFromDb(listKey);
    console.log('actions > list: ', result);
    dispatch(checklistDeleted(listKey));
  };
}

export function checklistDeleted(listKey) {
  return {
    type: CHECKLIST_DELETED,
    payload: { listKey }
  };
}

export function showChecklistForm() {
  return {
    type: SHOULD_SHOW_CHECKLIST_FORM,
    payload: true
  };
}

export function hideChecklistForm() {
  return {
    type: SHOULD_SHOW_CHECKLIST_FORM,
    payload: false
  };
}

export function toggleCompleted(item) {
  return {
    type: TOGGLE_COMPLETED,
    payload: item
  };
}

export function showChecklist(list) {
  return dispatch => {
    dispatch({ type: CLEAR_ITEMS });
    dispatch({ type: SET_ACTIVE_LIST_KEY, payload: list.listKey });
    dispatch({ type: SHOULD_SHOW_CHECKLIST, payload: true });
    dispatch(setupItemsListener(list));
  };
}

export function itemAdded(item) {
  return {
    type: ITEM_ADDED,
    payload: item
  };
}

export function itemRemoved(itemKey) {
  return {
    type: ITEM_REMOVED,
    payload: itemKey
  };
}

export function setupItemsListener(list) {
  return dispatch => {
    const itemsRef = database
      .ref('checklists')
      .child(list.listKey)
      .child('items');

    itemsRef.orderByChild('order').on('child_added', itemSnap => {
      const item = Object.assign({}, itemSnap.val(), {
        completed: false,
        itemKey: itemSnap.key
      });
      dispatch(itemAdded(item));
    });

    itemsRef.on('child_removed', itemSnap => {
      dispatch(itemRemoved(itemSnap.key));
    });

    itemsRef.on('child_changed', itemSnap => {
      dispatch(itemChanged(itemSnap.key, itemSnap.val()));
    });
  };
}

export function itemChanged(itemKey, itemData) {
  return {
    type: ITEM_CHANGED,
    payload: { itemKey, itemData }
  };
}

export function addItem(text) {
  // save a new item to the active checklist
  return (dispatch, getState) => {
    const { activeListKey } = getState();
    addItemToList(activeListKey, text);
  };
}

export function removeItem(item) {
  return (dispatch, getState) => {
    const { activeListKey } = getState();
    removeItemFromList(activeListKey, item.itemKey);
  };
}

export function updateItemOrder({ oldIndex, newIndex }) {
  return (dispatch, getState) => {
    const { activeListKey, items } = getState();
    const item = items.find(i => i.order - 1 === oldIndex);

    updateChecklistItemOrder(activeListKey, item.itemKey, newIndex);

    dispatch({ type: ITEMS_ORDER_CHANGED, payload: { oldIndex, newIndex } });
  };
}

export function updateItemText(itemKey, text) {
  return (dispatch, getState) => {
    const { activeListKey } = getState();
    updateItemTextOnFirebase(activeListKey, itemKey, text);
  };
}

export function gotList(data) {
  return {
    type: GOT_LIST,
    payload: data
  };
}

export function hideChecklist() {
  return { type: SHOULD_SHOW_CHECKLIST, payload: false };
}

export function hideChecklists() {
  return dispatch => {
    dispatch(hideChecklist);
    dispatch(hideChecklistForm);
  };
}

export function setupChecklistListeners(user) {
  return dispatch => {
    const checklistsRef = database.ref('checklists');
    const userKey = user.uid;

    checklistsRef
      .orderByChild('createdBy')
      .equalTo(userKey || null)
      .on('child_added', listSnap => {
        const data = Object.assign({}, listSnap.val(), {
          listKey: listSnap.key
        });
        dispatch(gotList(data));
      });
  };
}

export function textAboutNextItem(items) {
  var nextIncompleteItem = items.filter(i => !i.completed)[0];

  if (!nextIncompleteItem) return 'Your checklist is complete! Good job.';

  return 'It looks like you still need to ' + nextIncompleteItem.text;
}

function isTimeToSpeak(ms) {
  const seconds = Math.round(ms / 1000);

  return (
    seconds === 57.5 * 60 ||
    seconds === 52.5 * 60 ||
    seconds === 47.5 * 60 ||
    seconds === 42.5 * 60 ||
    seconds === 37.5 * 60 ||
    seconds === 32.5 * 60 ||
    seconds === 27.5 * 60 ||
    seconds === 22.5 * 60 ||
    seconds === 17.5 * 60 ||
    seconds === 12.5 * 60 ||
    seconds === 7.5 * 60 ||
    seconds === 3.5 * 60
  );
}

export function watchAndSpeakIfAppropriate() {
  return (dispatch, getState) => {
    setInterval(function() {
      const { shouldShowChecklist, items, timer } = getState();
      const { alarm, alarmIsSet } = timer;
      const ms = alarm - new Date().getTime();

      if (ms < 0) return;
      if (!shouldShowChecklist) return;
      if (!alarmIsSet) return;

      if (isTimeToSpeak(ms)) speakMsg(textAboutNextItem(items));
    }, 1000);
  };
}
