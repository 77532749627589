import React from 'react'; 
import Gif from './Gif'; 

export default function TimerDisplay(props) {
  const {
    remainingMin,
    remainingSecs,
    status,
    stopTimer,
    formattedTime,
    formattedAlarm
  } = props;

  return (
    <div>
      <p>Current time: {formattedTime}</p>
      <h3>{remainingMin} min {remainingSecs} secs left</h3>
      <p>Alarm: {formattedAlarm}</p>
      <Gif status={status} />
      <br />
      <button onClick={() => stopTimer()} className="btn btn-sm btn-info">
        Stop
      </button>
    </div>
  );
}
