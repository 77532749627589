import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import { userSigningOut } from '../actions/user';
import User from '../components/User'; 

class App extends Component {
  render() {
    const { user, signOut } = this.props;

    return <User user={user} signOut={signOut} />;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signOut() {
      dispatch(userSigningOut());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
