import React, { Component } from 'react';

class Checklist extends Component {
  render() {
    const { items } = this.props;

    if (!items || items === undefined) return (<div></div>);

    const numCompleted = items.reduce((total, item) => (item.completed ? total += 1 : total), 0)
    const percentComplete = Math.round((numCompleted / items.length) * 100);
    const backgroundBarHeight = '3.5em';
    const innerBarHeight = '3.2em';

    const progressBarStyles = {
      background: percentComplete === 100 ? 'green' : 'blue',
      width: `${percentComplete}%`,
      display: 'block',
      height: innerBarHeight,
    }

    return (
      <div>
        <div
          className="progessBarContainer"
          style={{
            position: 'relative',
            background: 'grey',
            border: '1px solid grey',
            height: backgroundBarHeight
          }}>
          <span className="progessBar" style={progressBarStyles}></span>
        </div>
        <div className="container">
          <h4 style={{ marginTop: '2em' }}>{numCompleted} out of {items.length} complete</h4>
        </div>
      </div>
    )
  }
}

export default Checklist;
