import React, { Component } from 'react';
import { Checkbox, Button, Glyphicon, ListGroupItem } from 'react-bootstrap';
import ChecklistItemEditForm from '../ChecklistItemEditForm';

import './index.css'

class ChecklistItem extends Component {
  constructor(props) {
    super(props);
    this.state = { shouldShowItemEditForm: false };
  }

  handleToggleTextForm = e => {
    if (e) e.preventDefault();

    const { shouldShowItemEditForm } = this.state;
    this.setState({ shouldShowItemEditForm: !shouldShowItemEditForm });
  };

  handleToggle = () => {
    this.props.toggleCompleted(this.props.item);
  };

  render() {
    const { item, removeItem, updateItemText } = this.props;
    const { shouldShowItemEditForm } = this.state;

    return (
      <ListGroupItem>
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>
            {shouldShowItemEditForm
              ? <ChecklistItemEditForm
                item={item}
                updateItemText={updateItemText}
                toggleTextForm={this.handleToggleTextForm}
              />
              : <Checkbox bsClass="item-checkbox" checked={item.completed} onChange={this.handleToggle}>
                {item.text}
              </Checkbox>}
          </div>
          <div style={{ flexBasis: '12%' }}>
            <Button bsStyle="link" onClick={this.handleToggleTextForm}>
              <Glyphicon glyph="pencil" />&nbsp;&nbsp;&nbsp;edit
            </Button>
          </div>
          <div>
            <Button bsStyle="link" onClick={() => removeItem(item)}>
              <Glyphicon glyph="trash" />&nbsp;&nbsp;&nbsp;delete
            </Button>
          </div>
        </div>
      </ListGroupItem>
    );
  }
}
export default ChecklistItem;
