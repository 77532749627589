import { combineReducers } from 'redux';
import user from './user';
import timer from './timer';
import lists from './lists';
import shouldShowChecklist from './shouldShowChecklist';
import shouldShowChecklistForm from './shouldShowChecklistForm';
import activeListKey from './activeListKey';
import items from './items';

const rootReducer = combineReducers({
  user,
  timer,
  lists,
  shouldShowChecklist,
  shouldShowChecklistForm,
  activeListKey,
  items
});

export default rootReducer;
