import React, { Component } from 'react';
import './index.css';
import statusColors from '../../utils/status-colors';
import TimerDisplay from '../TimerDisplay';
import TimerControls from '../TimerControls';

class Timer extends Component {
  getTimerStyles(status) {
    const { background, text } = statusColors(status);
    return {
      background,
      color: text
    };
  }

  render() {
    const { timer, updateTimerStatus, startTimer, stopTimer } = this.props;
    const {
      alarmIsSet,
      status,
      formattedTime,
      formattedAlarm,
      remainingMin,
      remainingSecs
    } = timer;

    return (
      <div className="timer" style={this.getTimerStyles(status)}>
        {alarmIsSet
          ? <TimerDisplay
            formattedAlarm={formattedAlarm}
            formattedTime={formattedTime}
            remainingMin={remainingMin}
            remainingSecs={remainingSecs}
            status={status}
            stopTimer={stopTimer}
          />
          : <TimerControls
            formattedTime={formattedTime}
            alarm={formattedAlarm}
            updateTimerStatus={updateTimerStatus}
            startTimer={startTimer}
          />}
      </div>
    );
  }
}

export default Timer;
