import firebase from 'firebase';
import firebaseui from 'firebaseui';

var config = {
  apiKey: "AIzaSyC54AptmkNmkfpxgOvDmcbSgjxMx4myPNU",
  authDomain: "familytalkingtimer.firebaseapp.com",
  databaseURL: "https://familytalkingtimer.firebaseio.com",
  storageBucket: "familytalkingtimer.appspot.com",
  messagingSenderId: "478115691216"
};

firebase.initializeApp(config);
window.ui = new firebaseui.auth.AuthUI(firebase.auth());

export const uiConfig = {
 signInOptions: [
   // Leave the lines as is for the providers you want to offer your users.
   firebase.auth.GoogleAuthProvider.PROVIDER_ID,
   firebase.auth.EmailAuthProvider.PROVIDER_ID
 ],
 signInFlow: 'popup',
 signInSuccessUrl: true
};

export default firebase;
export const database = firebase.database();
export const auth = firebase.auth();
