import initialState from './initialState';
import move from '../utils/array-move';
import {
  ITEM_ADDED,
  ITEM_REMOVED,
  CLEAR_ITEMS,
  TOGGLE_COMPLETED,
  ITEM_CHANGED,
  ITEMS_ORDER_CHANGED
} from '../constants/action-types';

function itemsWithToggledItem(state, action) {
  let items = state;
  let item = action.payload;
  let oldItem = items.filter(i => i.itemKey === item.itemKey)[0];
  let oldItemIndex = items.indexOf(oldItem);
  let newItem = Object.assign({}, item, { completed: !item.completed });
  let newItems = [
    ...items.slice(0, oldItemIndex),
    newItem,
    ...items.slice(oldItemIndex + 1)
  ];

  return newItems;
}

function itemsWithChangedItem(state, action) {
  let oldItems = state;
  let { itemKey, itemData } = action.payload;
  let item = oldItems.find(i => i.itemKey === itemKey);
  let newItem = Object.assign({}, item, itemData, { itemKey });
  let itemIndex = oldItems.indexOf(item);
  let newItems = [ ...oldItems ]
  newItems.splice(itemIndex, 1, newItem);
  return newItems;
}

function itemsInNewOrder(state, action) {
  const { oldIndex, newIndex } = action.payload;
  const newItems = move(state, oldIndex, newIndex );

  newItems.forEach((item, index) => item.order = index + 1)

  return newItems
}

export default function items(state = initialState.items, action) {
  switch (action.type) {
    case CLEAR_ITEMS:
      return [];
    case ITEM_ADDED:
      return [ ...state, action.payload];
    case ITEM_REMOVED:
      return state.filter(i => i.itemKey !== action.payload);
    case TOGGLE_COMPLETED:
      return itemsWithToggledItem(state, action);
    case ITEM_CHANGED:
      return itemsWithChangedItem(state, action);
    case ITEMS_ORDER_CHANGED:
      return itemsInNewOrder(state, action);
    default:
      return state;
  }
}
