import React from 'react';
import { Button } from 'react-bootstrap';

import './index.css';

function showChecklistChoices(lists, showChecklist) {
  return lists.map(list =>
    <Button onClick={() => showChecklist(list)}>
      {list.name}
    </Button>
  )
}

export default function ListOfChecklists(props) {
  const { lists, showChecklist, showChecklistForm } = props;

  return (
    <div className="container list-of-checklists">
      <h4>Your checklists:</h4>

      <div>
        {showChecklistChoices(lists, showChecklist)}
      </div>

      <Button
        className="btn-new-checklist"
        onClick={showChecklistForm}
      >
        + new checklist
      </Button>
    </div>
  );
}
