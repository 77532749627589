import React from 'react';
import { Button } from 'react-bootstrap';

import { alarmHour, alarmTenMinute, alarmMinute } from '../../utils/time';

import './index.css';

export default class TimerControls extends React.Component {
  handleTimerChange = (operation, amount) => {
    return () => this.props.updateTimerStatus(operation, amount);
  };

  render() {
    const { alarm, startTimer, formattedTime } = this.props;

    return (
      <div className="timer-controls">
        <h3><span className="current-time-header">Current time:</span> {formattedTime}</h3>
        <h4 className="timer-help-text">Set timer to:</h4>
        <div className="timer-controls-row">
          <div>
            <div>
              <Button
                bsSize="xsmall"
                onClick={this.handleTimerChange('add', 60)}
              >
                ▲
              </Button>
            </div>
            <div>{alarmHour(alarm)}</div>
            <Button
              bsSize="xsmall"
              onClick={this.handleTimerChange('subtract', 60)}
            >
              ▼
            </Button>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>&nbsp;</div>
            <div>:</div>
            <div>&nbsp;</div>
          </div>
          <div className="minute-tens-control">
            <div>
              <Button
                bsSize="xsmall"
                onClick={this.handleTimerChange('add', 10)}
              >
                ▲
              </Button>
            </div>
            <div>{alarmTenMinute(alarm)}</div>
            <Button
              bsSize="xsmall"
              onClick={this.handleTimerChange('subtract', 10)}
            >
              ▼
            </Button>
          </div>
          <div>
            <div>
              <Button
                bsSize="xsmall"
                onClick={this.handleTimerChange('add', 1)}
              >
                ▲
              </Button>
            </div>
            <div>{alarmMinute(alarm)}</div>
            <Button
              bsSize="xsmall"
              onClick={this.handleTimerChange('subtract', 1)}
            >
              ▼
            </Button>
          </div>
        </div>
        <Button
          className="btn btn-success btn-start-timer"
          bsStyle="large"
          bsSize="large"
          onClick={() => startTimer()}
        >
          Start!
        </Button>
      </div>
    );
  }
}

// Original render:
//
// <div>
//   <hr />
//   <Button onClick={() => updateTimerStatus('add', 60)}>+</Button>
//   <p>Hour</p>
//   <Button onClick={() => updateTimerStatus('subtract', 60)}>-</Button>
//   <br /><hr />
//   <Button onClick={() => updateTimerStatus('add', 1)}>+ 1</Button>
//   <Button onClick={() => updateTimerStatus('add', 5)}>+ 5</Button>
//   <Button onClick={() => updateTimerStatus('add', 20)}>+ 20</Button>
//   <p>Minute</p>
//   <Button onClick={() => updateTimerStatus('subtract', 1)}>- 1</Button>
//   <Button onClick={() => updateTimerStatus('subtract', 5)}>- 5</Button>
//   <Button onClick={() => updateTimerStatus('subtract', 20)}>- 20</Button>
//   <br />
//   <hr />
//   <Button
//     className="btn btn-success"
//     bsSize="large"
//     onClick={() => startTimer()}
//   >
//     Start!
//   </Button>
// </div>
