import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Login from './Login';

export function UserDetails(props) {
  const { user, signOut } = props;

  return (
    <div style={{ marginTop: '2em' }}>
      <p className="small">Signed in as: {user.email}</p>
      <Button bsSize="xsmall" onClick={signOut}>Sign Out</Button>
    </div>
  );
}

class User extends Component {
  render() {
    const { user, signOut } = this.props;
    const signedIn = user && user.email;

    return (
      <div>
        {signedIn ? <UserDetails signOut={signOut} user={user} /> : <Login />}
      </div>
    );
  }
}

export default User;
