import React, { Component } from 'react';
import {
  Button,
  Form,
  FormGroup,
  FormControl
} from 'react-bootstrap';;

export default class NewItemForm extends Component {
  state = { newItem: '' };

  handleChange = e => {
    this.setState({ newItem: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.addItem(this.state.newItem);
    this.setState({ newItem: '' });
  };

  render() {
    return (
      <Form inline onSubmit={this.handleSubmit}>
        <FormGroup>
          <FormControl
            type="text"
            value={this.state.newItem}
            onChange={this.handleChange}
            placeholder="New item..."
            style={{ marginRight: '1em' }}
          />
        </FormGroup>
        <Button type="submit" bsStyle="success">
          <span className="glyphicon glyphicon-plus" />&nbsp;&nbsp;&nbsp;Add item
        </Button>
      </Form>
    );
  }
}
