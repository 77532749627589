import { SIGN_IN, SIGN_OUT } from '../constants/action-types';

export default function user (state = {}, action) {
  switch (action.type) {
    case SIGN_IN:
      return action.payload;
    case SIGN_OUT:
      return {};
    default:
      return state;
  }
}
