import Timer from 'timer.js';
import moment from 'moment';
import { database, auth } from '../services/firebase';
import { soundStart, soundEnd, checkDuration } from '../utils/timer-callbacks';

let timer;
let alarm;
let alarmIsSet;

function getStatusAsColor(seconds) {
  if (!timer) return 'grey';
  if (!alarmIsSet) return 'grey';
  if (seconds < 60) return 'red';
  if (seconds < 1 * 60) return 'orange';
  if (seconds < 5 * 60) return 'yellow';
  if (seconds < 10 * 60) return 'green';
  return 'blue';
}

export function getTimerStatus() {
  const diff = remainingDuration();
  const seconds = Math.round(diff / 1000);
  const remainingMin = Math.floor(seconds / 60);
  const remainingHour = Math.floor(remainingMin / 60);
  const remainingSecs = seconds % 60;

  return {
    alarm,
    alarmIsSet,
    formattedTime: moment(Date.now()).format('h:mm:ss A'),
    formattedAlarm: moment(alarm).format('h:mm A'),
    remainingHour: remainingHour >= 0 ? remainingHour : 0,
    remainingMin: remainingMin >= 0 ? remainingMin : 0,
    remainingSecs: remainingSecs >= 0 ? remainingSecs : 0,
    status: getStatusAsColor(seconds)
  };
}

export function startTimer(opts = {}) {
  const seconds = moment(getTimer()).diff(Date.now(), 'seconds');

  timer = new Timer({
    onstart: () => soundStart(seconds),
    ontick: checkDuration,
    onend: soundEnd
  });

  timer.start(seconds);
  alarmIsSet = true;

  if (!opts.clientOnly) saveTimerStart(getTimer());
}

export function stopTimer(opts = {}) {
  if (timer) timer.stop();
  alarmIsSet = false;

  if (!opts.clientOnly) saveTimerStop();
}

export function remainingDuration() {
  if (!timer) return;

  return timer.getDuration();
}

export function setTimer() {
  const newTimer = moment(Date.now());

  newTimer.add(25, 'minutes');
  newTimer.set('seconds', 0);

  alarm = newTimer.valueOf();

  return alarm;
}

export function getTimer() {
  return alarm || setTimer();
}

export function addMinutes(num) {
  alarm += 1000 * 60 * num;
}

export function subtractMinutes(num) {
  const currentTime = Date.now();
  let newTimer = alarm - 1000 * 60 * num;
  if (newTimer < currentTime) {
    newTimer = moment(currentTime);
    newTimer.add(1, 'minutes');
    newTimer.set('seconds', 0);
  }

  alarm = newTimer.valueOf();
}

// Firebase stuff
async function getUserKey() {
  const user = await auth.currentUser;
  return user && user.uid;
}

function updateServerTimer(userKey, opts) {
  database.ref('timers').child(userKey).set(opts);
}

export async function saveTimerStart(alarm) {
  const userKey = await getUserKey();
  if (userKey) updateServerTimer(userKey, { alarm, alarmIsSet: true });
}

export async function saveTimerStop() {
  const userKey = await getUserKey();
  if (userKey) updateServerTimer(userKey, { alarm: 0, alarmIsSet: false });
}
