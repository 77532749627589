import {
  msToSeconds,
  secondsToMinutes,
  minutesLeft,
  timeIsDivisibleBy5Min
} from './time';
import { speakMsg } from './speaking';

function showRemainingTimeInDocumentTitle(seconds) {
  const minutes = secondsToMinutes(seconds);
  const remainderSeconds = seconds % 60;

  document.title = `${minutes}:${remainderSeconds < 10 ? '0' : ''}${remainderSeconds}`;
}

function speakRemainingTime(seconds) {
  if (minutesLeft(seconds, 60)) speakMsg('1 hour left');

  if (timeIsDivisibleBy5Min(seconds)) speakMsg(minutesLeft(seconds) + ' minutes left');

  if (minutesLeft(seconds, 4)) speakMsg('four minutes left');
  if (minutesLeft(seconds, 3)) speakMsg('three minutes left');
  if (minutesLeft(seconds, 2)) speakMsg('two minutes left');
  if (minutesLeft(seconds, 1.5)) speakMsg('one minute and thirty seconds left');
  if (minutesLeft(seconds, 1)) speakMsg('one minute left');

  if (seconds === 45) speakMsg('forty five seconds left');
  if (seconds === 30) speakMsg('thirty seconds left');
  if (seconds === 15) speakMsg('fifteen seconds left');
  if (seconds === 10) speakMsg('ten seconds left');
  if (seconds === 5) speakMsg('five seconds left');
}

export function checkDuration(ms) {
  const seconds = msToSeconds(ms);

  showRemainingTimeInDocumentTitle(seconds);
  speakRemainingTime(seconds);
}

export function soundStart(seconds) {
  speakMsg('Get going, the timer has started! you have about ' + Math.round(seconds / 60) + ' minutes.')
}

export function soundEnd() {
  speakMsg('Time is up! I repeat. Time is up!');
}
