import initialState from './initialState';
import { SHOULD_SHOW_CHECKLIST_FORM } from '../constants/action-types';

export default function lists(state = initialState.shouldShowChecklistForm, action) {
  switch (action.type) {
    case SHOULD_SHOW_CHECKLIST_FORM:
      return action.payload;
    default:
      return state;
  }
}
