import initialState from './initialState';
import { GOT_LIST, CHECKLIST_DELETED } from '../constants/action-types';

export default function lists(state = initialState.lists, action) {
  switch (action.type) {
    case GOT_LIST:
      const alreadyInState = state.find(
        list => list.listKey === action.payload.listKey
      );
      if (alreadyInState) {
        return state;
      } else {
        return [...state, action.payload];
      }
    case CHECKLIST_DELETED:
      return state.filter(list => list.listKey !== action.payload.listKey);
    default:
      return state;
  }
}
