import thunk from 'redux-thunk';
import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from './index';
import { startListeningToAuthChanges } from '../actions/user';

const middleware = [thunk];
const enhancers = [];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function setupStore() {
  const store = createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );

  store.dispatch(startListeningToAuthChanges());

  return store;
}
