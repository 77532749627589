import moment from 'moment';
import { UPDATE_TIMER_STATUS, STOP_TIMER } from '../constants/action-types';
import { database } from '../services/firebase';
import {
  getTimer,
  addMinutes,
  subtractMinutes,
  getTimerStatus,
  startTimer,
  stopTimer
} from '../api/timer-api';

export function updateTimerStatus(operator, num) {
  return dispatch => {
    // add or subtract minutes if necessary
    if (operator === 'add') addMinutes(num);
    if (operator === 'subtract') subtractMinutes(num);

    dispatch({
      type: UPDATE_TIMER_STATUS,
      payload: getTimerStatus()
    });
  };
}

export function timerStarted(opts) {
  return dispatch => {
    startTimer(opts);
    dispatch(updateTimerStatus());
  };
}

export function timerStopped(opts) {
  return dispatch => {
    stopTimer(opts);

    dispatch({
      type: STOP_TIMER,
      payload: opts
    });
  };
}

export function initializeTimer() {
  return dispatch => {
    getTimer();
    dispatch(updateTimerStatus());
  };
}

export function setupTimerListeners(user) {
  return (dispatch, getState) => {
    const userKey = user.uid;
    const timerRef = database.ref('timers').child(userKey);

    // if the alarm on the server has stopped, we want to stop the client's timer.
    // if the alarm on the server is running, we want to start the client's timer with the same alarm value.
    timerRef.on('value', timerSnap => {
      const serverAlarmValue = timerSnap.val().alarm;
      const alarmIsSetOnServer = timerSnap.val().alarmIsSet;
      const alarmIsSetOnClient = getState().timer.alarmIsSet;
      const nowTimestamp = Date.now();

      function shouldFollowAlarmOnServer() {
        return (
          moment(serverAlarmValue) > moment(nowTimestamp) && alarmIsSetOnServer
        );
      }

      function shouldStopClientAlarm() {
        return (
          (alarmIsSetOnServer === false || serverAlarmValue === 0) &&
          alarmIsSetOnClient
        );
      }

      function startFollowingAlarmOnServer() {
        const minutes = moment(serverAlarmValue).diff(nowTimestamp, 'minutes');

        dispatch(timerStopped({ clientOnly: true }));
        dispatch(updateTimerStatus('subtract', 9999999));
        dispatch(updateTimerStatus('add', minutes));
        // TODO: set timer alarm to the server's alarm
        dispatch(timerStarted({ clientOnly: true }));
      }

      if (shouldStopClientAlarm()) dispatch(timerStopped({ clientOnly: true }));
      if (shouldFollowAlarmOnServer()) startFollowingAlarmOnServer();
    });
  };
}
