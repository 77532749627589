import initialState from './initialState';
import {
  SHOULD_SHOW_CHECKLIST,
  CHECKLIST_DELETED
} from '../constants/action-types';

export default function lists(
  state = initialState.shouldShowChecklist,
  action
) {
  switch (action.type) {
    case SHOULD_SHOW_CHECKLIST:
      return action.payload;
    case CHECKLIST_DELETED:
      return false;
    default:
      return state;
  }
}
