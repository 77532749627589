import React, { Component } from 'react';
import { uiConfig } from '../../services/firebase';

import 'firebaseui/dist/firebaseui.css';
import './index.css';

// FirebaseUI config.
class User extends Component {
  componentDidMount() {
    if (!document.getElementById('firebaseui-auth-container')) {
      return;
    }

    window.ui.start('#firebaseui-auth-container', uiConfig);
  }

  render() {
    return (
      <div className='login'>
        <p className='text-center text-muted'>Sign in for checklists!</p>
        <div id="firebaseui-auth-container" />
      </div>
    );
  }
}

export default User;
