import React, { Component } from 'react';
import {
  Button,
  Form,
  FormGroup,
  FormControl
} from 'react-bootstrap';

export default class ChecklistItemEditForm extends Component {
  state = { itemText: this.props.item.text };
  handleSubmit = e => {
    e.preventDefault();
    const textContent = this.state.itemText;
    this.props.updateItemText(this.props.item.itemKey, textContent);
    this.props.toggleTextForm();
  };

  handleChange = e => {
    this.setState({ itemText: e.target.value });
  };

  render() {
    return (
      <Form inline onSubmit={this.handleSubmit}>
        <FormGroup>
          <FormControl
            type="text"
            ref="textContent"
            value={this.state.itemText}
            onChange={this.handleChange}
            style={{ minWidth: '100%' }}
          />
        </FormGroup>
        <Button type="submit">Save</Button>
      </Form>
    );
  }
}
