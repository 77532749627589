export function msToSeconds(ms) {
  return Math.round(ms / 1000);
}
export function secondsToMinutes(seconds) {
  return Math.floor(seconds / 60);
}

export function minutesLeft(seconds, minutes) {
  if (minutes) return seconds === minutes * 60;
  return secondsToMinutes(seconds);
}

export function timeIsDivisibleBy5Min(seconds) {
  return seconds / 60 % 5 === 0;
}

export function alarmHour(alarm = '12:00 AM') {
  return alarm.split(':')[0];
}

export function alarmTenMinute(alarm = '12:00 AM') {
  return alarm.split(':')[1].split(' ')[0][0];
}

export function alarmMinute(alarm = '12:00 AM') {
  return alarm.split(':')[1].split(' ')[0][1];
}
