import initialState from './initialState';
import {
  SET_ACTIVE_LIST_KEY,
  CHECKLIST_DELETED
} from '../constants/action-types';

export default function lists(state = initialState.lists, action) {
  switch (action.type) {
    case SET_ACTIVE_LIST_KEY:
      return action.payload;
    case CHECKLIST_DELETED:
      return '';
    default:
      return state;
  }
}
