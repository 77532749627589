import { auth } from '../services/firebase';
import { SIGN_IN, SIGN_OUT } from '../constants/action-types';
import { setupTimerListeners } from './timer';
import { setupChecklistListeners, hideChecklists } from './list';

function signIn(user) {
  return {
    type: SIGN_IN,
    payload: user
  }
}

function signOut() {
  return {
    type: SIGN_OUT
  }
}

export function userSigningOut() {
  return (dispatch) => {
    auth.signOut();
    dispatch(signOut);
  }
}

export function startListeningToAuthChanges() {
  return (dispatch) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(setupTimerListeners(user));
        dispatch(setupChecklistListeners(user));
        dispatch(signIn(user));
      } else {
        dispatch(hideChecklists())
        dispatch(signOut(user));
      }
    });
  }
}
