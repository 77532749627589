import React, { Component } from 'react';

import ChecklistForm from './ChecklistForm';
import ListOfChecklists from './ListOfChecklists';

export default class ChecklistChooser extends Component {
  render() {
    const {
      shouldShowChecklistForm,
      lists,
      showChecklist,
      showChecklistForm,
      createChecklist,
      hideChecklistForm
    } = this.props;

    if (shouldShowChecklistForm)
      return (
        <ChecklistForm
          createChecklist={createChecklist}
          hideChecklistForm={hideChecklistForm}
        />
      );

    return (
      <ListOfChecklists
        lists={lists}
        showChecklist={showChecklist}
        showChecklistForm={showChecklistForm}
      />
    );
  }
}
