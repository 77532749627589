import { UPDATE_TIMER_STATUS } from '../constants/action-types';

export default function timer (state = {}, action) {
  switch (action.type) {
    case UPDATE_TIMER_STATUS:
      return action.payload
    default:
      return state;
  }
}
