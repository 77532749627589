let timerVoice;

// Browser loads voices async, so need to listen
// for the onvoiceschanged event in order to get
// the list of voices
if (typeof window.speechSynthesis === 'undefined') {
  window.speechSynthesis = {};
}

window.speechSynthesis.onvoiceschanged = function() {
  setTimerVoice();
};

export function getDefaultVoice(lang = 'EN') {
  return window.speechSynthesis
    .getVoices()
    .find(voice => voice.lang === 'en-US');
}

export function setTimerVoice(voice) {
  if (!voice) return;

  return (timerVoice = voice);
}

export function getTimerVoice() {
  return timerVoice || getDefaultVoice();
}
