export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

export const UPDATE_TIMER_STATUS = 'UPDATE_TIMER_STATUS';
export const STOP_TIMER = 'STOP_TIMER';

export const GOT_LIST = 'GOT_LIST';
export const SHOULD_SHOW_CHECKLIST = 'SHOULD_SHOW_CHECKLIST';
export const SHOULD_SHOW_CHECKLIST_FORM = 'SHOULD_SHOW_CHECKLIST_FORM';
export const SET_ACTIVE_LIST_KEY = 'SET_ACTIVE_LIST_KEY';
export const ITEM_ADDED = 'ITEM_ADDED';
export const CLEAR_ITEMS = 'CLEAR_ITEMS';
export const ITEM_REMOVED = 'ITEM_REMOVED';
export const TOGGLE_COMPLETED = 'TOGGLE_COMPLETED';
export const ITEM_CHANGED = 'ITEM_CHANGED';
export const ITEMS_ORDER_CHANGED = 'ITEMS_ORDER_CHANGED';
export const CHECKLIST_DELETED = 'CHECKLIST_DELETED';
