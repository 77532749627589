const initialState = {
  lists: [],
  items: [],
  activeListKey: undefined,
  shouldShowChecklist: false,
  shouldShowChecklistForm: false,
  user: {},
  timer: {}
}

export default initialState;
