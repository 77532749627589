import React from 'react';
import { Button, Glyphicon } from 'react-bootstrap';

export default class DeleteChecklist extends React.Component {
  handleClick = () => {
    if (!window.confirm('Are you sure you want to delete this checklist?'))
      return;
    this.props.deleteChecklist(this.props.activeListKey);
  };

  render() {
    return (
      <Button bsSize="xsmall" bsStyle="danger" onClick={this.handleClick}>
        <Glyphicon glyph="trash" />&nbsp;&nbsp;&nbsp;Delete Checklist
      </Button>
    );
  }
}
