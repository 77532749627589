import { database, auth } from '../services/firebase';

export async function saveChecklist(name) {
  const listsRef = database.ref('checklists');
  const result = await listsRef.push({
    name,
    itemsCount: 0,
    createdBy: auth.currentUser.uid
  });

  return result;
}

export async function addItemToList(listKey, text) {
  const listRef = database.ref('checklists').child(listKey);
  const oldCount = await listRef.child('itemsCount').once('value');
  const count = oldCount.val() + 1;

  listRef.child('items').push({ text, order: count });
  listRef.child('itemsCount').set(count);
}

export async function removeItemFromList(listKey, itemKey) {
  const listRef = database.ref('checklists').child(listKey);
  const oldCount = await listRef.child('itemsCount').once('value');
  const count = oldCount.val() - 1;

  const oldItemSnap = await listRef.child('items').child(itemKey).once('value');

  listRef.child('items').child(itemKey).remove();
  listRef.child('itemsCount').set(count);

  // TODO: update item order for succeeding items
  const itemsRef = listRef.child('items');
  const itemsSnap = await itemsRef.once('value');

  itemsSnap.forEach(otherItemSnap => {
    const otherItemOrder = otherItemSnap.val().order;
    const oldItemOrder = oldItemSnap.val().order;
    if (otherItemOrder > oldItemOrder) {
      listRef
        .child('items')
        .child(otherItemSnap.key)
        .update({ order: otherItemOrder - 1 });
    }
  });
}

export async function updateChecklistItemOrder(listKey, itemKey, newIndex) {
  const itemsRef = await database
    .ref('checklists')
    .child(listKey)
    .child('items');
  const itemsSnap = await itemsRef.once('value');
  const itemRef = await itemsRef.child(itemKey);
  const oldOrderSnap = await itemRef.child('order').once('value');
  const oldOrder = oldOrderSnap.val();
  const newOrder = newIndex + 1;

  itemsSnap.forEach(itemSnap => {
    const itemOrder = itemSnap.val().order;

    if (itemOrder < oldOrder && itemOrder < newOrder) {
      return;
    }

    if (itemOrder > oldOrder && itemOrder > newOrder) {
      return;
    }

    if (itemSnap.key === itemKey) {
      itemRef.update({ order: newOrder });
      return;
    }

    // sometimes we shift lift, sometimes we shift right...
    let shift;
    if (oldOrder < newOrder) shift = itemOrder - 1;
    if (oldOrder > newOrder) shift = itemOrder + 1;

    itemsRef.child(itemSnap.key).update({ order: shift });
    return;
  });
}

export async function updateItemTextOnFirebase(listKey, itemKey, text) {
  database
    .ref('checklists')
    .child(listKey)
    .child('items')
    .child(itemKey)
    .update({ text });
}

export async function deleteChecklistFromDb(listKey) {
  return database.ref('checklists').child(listKey).remove();
}
