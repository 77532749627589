import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Button, ListGroup } from 'react-bootstrap';

import ChecklistProgress from '../ChecklistProgress';
import ChecklistItem from '../ChecklistItem';
import NewItemForm from '../NewItemForm';
import DeleteChecklist from '../DeleteChecklist';

export default class Checklist extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.updateItemOrder({ oldIndex, newIndex });
  };

  render() {
    const {
      activeListKey,
      items,
      toggleCompleted,
      addItem,
      removeItem,
      updateItemText,
      hideChecklist,
      deleteChecklist
    } = this.props;

    if (!items) return <div />;

    const SortableItem = SortableElement(({ item }) => {
      return (
        <ChecklistItem
          item={item}
          toggleCompleted={toggleCompleted}
          removeItem={removeItem}
          updateItemText={updateItemText}
        />
      );
    });

    const SortableList = SortableContainer(({ items }) => {
      return (
        <ListGroup>
          {items.map((item, index) =>
            <SortableItem key={item.itemKey} index={index} item={item} />
          )}
        </ListGroup>
      );
    });

    return (
      <div>
        <ChecklistProgress items={items} />
        <div
          className="container"
          style={{ marginTop: '2em' }}
        >
          <SortableList
            items={items}
            onSortEnd={this.onSortEnd}
            pressDelay={100}
          />
          <NewItemForm addItem={addItem} />
          <Button
            onClick={hideChecklist}
            style={{ marginTop: '2em', marginBottom: '2em' }}
          >
            ← go back
          </Button>
          <div>
            <DeleteChecklist
              deleteChecklist={deleteChecklist}
              activeListKey={activeListKey}
            />
          </div>
        </div>
      </div>
    );
  }
}
