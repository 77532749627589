import React, { Component } from 'react'; 
import { Button } from 'react-bootstrap'; 
import { setNewGif, getGifUrl } from '../utils/gif-utils';

class Gif extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.status !== this.props.status) {
      setNewGif();
    }
  }

  render() {
    const url = getGifUrl();

    if (!url) return <div style={{ height: '6em' }}> </div>;

    return (
      <div>
        <img src={url} alt={'Funny animated gif'} />
        <br />
        <Button bsSize="xsmall" bsStyle="info" onClick={setNewGif}>
          Change gif
        </Button>
      </div>
    );
  }
}

export default Gif;
