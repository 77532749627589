const red = '#EE25FF';
const orange = '#FF7F66';
const yellow = '#FFF6E5';
const green = '#7ECEFC';
const blue = '#2186C4';
const grey = '#3F464C';

export default function statusColors(status) {
  switch (status) {
    case 'red':
      return {
        background: red,
        text: 'white',
      }
    case 'orange':
      return {
        background: orange,
        text: 'white',
      }
    case 'yellow':
      return {
        background: yellow,
        text: 'black',
      }
    case 'green':
      return {
        background: green,
        text: 'white',
      }
    case 'blue':
      return {
        background: blue,
        text: 'white',
      }
    default:
      return {
        background: grey,
        text: 'white',
      }
  }
}
