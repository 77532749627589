const terms = [ 'animals', 'funny+puppy', 'funny+cat' ];
const q = terms[Math.floor(Math.random() * terms.length)];
const limit = 50;
const apiKey = 'dc6zaTOxFJmzC';
const url = `https://api.giphy.com/v1/gifs/search?q=${q}&limit=${limit}&api_key=${apiKey}`

let giphyResults = [];
let gifUrl;

function useCachedGif() {
  const rand = giphyResults[Math.floor(Math.random() * giphyResults.length)];

  return gifUrl = rand.images.fixed_height.url;
}

function fetchAndUseNewGif() {
  fetch(url)
    .then(response => response.json())
    .then(json => {
      const rand = json.data[Math.floor(Math.random() * json.data.length)];

      gifUrl = rand.images.fixed_height.url;
      giphyResults = json.data;

      return
    })
    .catch(e => console.log('fetchAndUseNewGif: error in fetch: ', e));
}

export function setNewGif() {
  if (giphyResults.length >= 1) return useCachedGif();

  fetchAndUseNewGif();
}

export function getGifUrl() {
  if (!gifUrl) setNewGif();

  return gifUrl;
}
