import React, { Component } from 'react';
import './index.css';

import TimerContainer from '../../containers/TimerContainer';
import UserContainer from '../../containers/UserContainer';
import ChecklistContainer from '../../containers/ChecklistContainer';

const styles = {
  border: '1px solid grey'
}

class App extends Component {
  render() {
    return (
      <div styles={styles}>
        <h1 className="logo">Family Talking Timer</h1>
        <TimerContainer />
        <ChecklistContainer />
        <UserContainer />
      </div>
    );
  }
}
export default App;
