import React, { Component } from 'react'; 
import { connect } from 'react-redux';

import {
  addItem,
  removeItem,
  createChecklist,
  deleteChecklist,
  toggleCompleted,
  showChecklist,
  showChecklistForm,
  hideChecklistForm,
  updateItemOrder,
  updateItemText,
  hideChecklist,
  watchAndSpeakIfAppropriate
} from '../actions/list';

import ChecklistChooser from '../components/ChecklistChooser'; 
import Checklist from '../components/Checklist'; 

class ChecklistContainer extends Component {
  componentDidMount() {
    this.props.watchAndSpeakIfAppropriate();
  }

  render() {
    const { user, shouldShowChecklist } = this.props;
    const userNotLoggedIn = !(user && user.email);

    if (userNotLoggedIn) return <div />;
    if (shouldShowChecklist) return <Checklist {...this.props} />;
    return <ChecklistChooser {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    lists: state.lists,
    activeListKey: state.activeListKey,
    items: state.items,
    shouldShowChecklist: state.shouldShowChecklist,
    shouldShowChecklistForm: state.shouldShowChecklistForm
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addItem(text) {
      dispatch(addItem(text));
    },
    removeItem(item) {
      dispatch(removeItem(item));
    },
    createChecklist(name) {
      dispatch(createChecklist(name));
    },
    deleteChecklist(listKey) {
      dispatch(deleteChecklist(listKey));
    },
    toggleCompleted(item) {
      dispatch(toggleCompleted(item));
    },
    showChecklist(list) {
      dispatch(showChecklist(list));
    },
    showChecklistForm() {
      dispatch(showChecklistForm());
    },
    hideChecklistForm() {
      dispatch(hideChecklistForm());
    },
    updateItemOrder({ oldIndex, newIndex }) {
      dispatch(updateItemOrder({ oldIndex, newIndex }));
    },
    updateItemText(itemKey, text) {
      dispatch(updateItemText(itemKey, text));
    },
    hideChecklist() {
      dispatch(hideChecklist());
    },
    watchAndSpeakIfAppropriate() {
      dispatch(watchAndSpeakIfAppropriate());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistContainer);
