import React, { Component } from 'react'; 
import { connect } from 'react-redux';

import Timer from '../components/Timer'; 

import {
  initializeTimer,
  updateTimerStatus,
  timerStarted,
  timerStopped
} from '../actions/timer';

class TimerContainer extends Component {
  constructor(props) {
    const { initializeTimer, updateTimerStatus } = props;

    super(props);
    initializeTimer();
    setInterval(updateTimerStatus, 1000);
  }

  render() {
    const {
      user,
      timer,
      startTimer,
      stopTimer,
      updateTimerStatus
    } = this.props;

    return (
      <Timer
        user={user}
        timer={timer}
        startTimer={startTimer}
        stopTimer={stopTimer}
        updateTimerStatus={updateTimerStatus}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    timer: state.timer
  };
}

function mapDispatchToProps(dispatch) {
  return {
    initializeTimer() {
      dispatch(initializeTimer());
    },
    updateTimerStatus(operator, num) {
      dispatch(updateTimerStatus(operator, num));
    },
    startTimer(opts) {
      dispatch(timerStarted(opts));
    },
    stopTimer(opts) {
      dispatch(timerStopped(opts));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimerContainer);
