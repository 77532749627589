import React, { Component } from 'react';
import { Button, FormGroup, FormControl } from 'react-bootstrap';

export default class ChecklistForm extends Component {
  state = { newChecklist: '' };

  handleFormSubmit = e => {
    e.preventDefault();

    this.props.createChecklist(this.state.newChecklist);
  };

  handleChange = e => {
    this.setState({ newChecklist: e.target.value });
  };

  onCancel = () => {
    this.props.hideChecklistForm();
  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleFormSubmit}>
          <FormGroup>
            <FormControl
              type="text"
              value={this.state.newChecklist}
              placeholder="Please name this checklist..."
              onChange={this.handleChange}
            />
          </FormGroup>
          <Button type="submit">Add Checklist</Button>
          <Button onClick={this.onCancel}>Cancel</Button>
        </form>
      </div>
    );
  }
}
